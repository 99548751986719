@import url("https://fonts.googleapis.com/css?family=Pacifico");
/* header and navbar */
header {
  background-color: #FFF;
}

.navbar {
  margin-bottom: 0;
  min-height: 50px;
}

.navbar-brand {
  padding: 10px 0;
}

.navbar-brand img {
  max-width: 160px;
}

.navbar-nav > li > a {
  padding-top: 20px;
  color: #6b3e99;
}

.nav > li > a:focus, .nav > li > a:hover {
  background-color: transparent;
  color: #e04590;
}

.navbar-toggle {
  background-color: #18b8df;
  margin-right: 0;
}

.navbar-toggle .icon-bar {
  background-color: white;
}

/* body, main and basic styles */
body {
  color: #666;
  font: 16px "Lato", sans-serif;
  line-height: 23px;
  word-wrap: break-word;
}

a {
  color: #6b3e99;
}

a:hover, a:active {
  color: #e04590;
}

h1, h2, h3, h4, h5, h6 {
  color: #18b8df;
  font-family: 'Pacifico', cursive;
  letter-spacing: 1px;
}

ul {
  list-style: none;
  -webkit-padding-start: 0;
}

p {
  margin: 0 0 20px;
}

input[type="text"], input[type="email"], input[type="search"], input[type="password"], input[type="tel"], input[type="url"], input[type="date"], textarea {
  padding: 10px;
  border: 1px solid rgba(0, 0, 0, 0.05);
  width: 100%;
  margin: 0 0 20px;
  background-color: #f9f9f9;
  -webkit-border-radius: 3px;
  -moz-border-radius: 3px;
  border-radius: 3px;
}

input[type="reset"], input[type="button"], input[type="submit"] {
  background-color: #6b3e99;
  border: 1px solid #6b3e99;
  color: #FFF;
  cursor: pointer;
  margin-bottom: 30px;
  text-transform: uppercase;
  display: block;
  text-align: center;
  height: 40px;
  padding: 0 20px;
  margin-top: 5px;
  -webkit-border-radius: 3px;
  -moz-border-radius: 3px;
  border-radius: 3px;
}

main {
  padding: 30px 0 40px;
}

/* blog posts */
.tip-excerpt, .tip {
  padding: 0 0 20px;
  margin-bottom: 30px;
  text-align: left;
  clear: both;
  min-height: 180px;
  border-radius: 10px;
  background: #dceaae;
}

.tip-excerpt p.title, .tip p.title {
  font-family: "Pacifico", cursive;
  font-size: 28px;
  margin: 30px 0 20px;
  display: inline-block;
  width: auto;
}

.tip-excerpt p.title a:hover, .tip p.title a:hover {
  text-decoration: none;
}

.tip-excerpt p, .tip p {
  width: 98%;
  line-height: 26px;
}

.tip-excerpt img, .tip img {
  text-align: center;
  border: 5px solid #18b8df !important;
  border-radius: 50%;
  background-color: #FFF !important;
  float: left;
  max-width: 140px;
  margin: 15px;
  -webkit-transition: -webkit-transform 600ms ease-in-out;
  transition: transform 600ms ease-in-out;
}

.tip-excerpt img:hover, .tip img:hover {
  -webkit-transform: rotate(360deg);
  transform: rotate(360deg);
}

/*spotlight careers*/
.post-excerpt, .post {
  padding: 0 0 20px;
  margin-bottom: 40px;
  clear: both;
  min-height: 250px;
  border-radius: 10px;
  background: #dceaae;
}

.post-excerpt p.title, .post p.title {
  font-family: "Pacifico", cursive;
  font-size: 28px;
  margin: 40px 0 30px;
  display: inline-block;
}

.post-excerpt p.title a:hover, .post p.title a:hover {
  text-decoration: none;
}

.post-excerpt p, .post p {
  max-width: 98%;
  line-height: 26px;
}

.post-excerpt img, .post img {
  border: 5px solid #18b8df !important;
  border-radius: 50%;
  background-color: #FFF !important;
  float: left;
  max-width: 200px;
  margin: 30px 15px 40px;
  -webkit-transition: -webkit-transform 600ms ease-in-out;
  transition: transform 600ms ease-in-out;
}

.post-excerpt img:hover, .post img:hover {
  -webkit-transform: rotate(360deg);
  transform: rotate(360deg);
}

.post-excerpt .excerpt, .post .excerpt {
  display: inline-block;
  width: 88%;
}

.post-excerpt a.button, .post a.button {
  color: #FFF;
  letter-spacing: .1em;
  background-color: #e04590;
  border-radius: 3px 3px 3px 3px;
  cursor: pointer;
  display: inline-block;
  font-size: 13px;
  line-height: 20px;
  padding: 9px 18px;
  text-align: center;
}

.post-excerpt a.button:hover, .post a.button:hover {
  text-decoration: none;
  background-color: #18b8df;
}

.pagination {
  display: block;
  min-height: 50px;
  text-align: center;
  margin-bottom: 50px;
}

.pagination a {
  color: #6b3e99;
  border: 1px solid #ededed;
  padding: 5px 10px;
  text-align: center;
  margin: 5px;
  border-radius: 4px;
  display: inline-block;
}

.pagination a:hover {
  border: 1px solid #6b3e99;
  text-decoration: none;
}

@media screen and (max-width: 990px) {
  .post-excerpt img, .post img, .tip-excerpt img, .tip img {
    float: none;
    margin: 15px auto;
  }
  .post-excerpt, .post, .tip-excerpt, .tip {
    text-align: center;
  }
  .post-excerpt p, .post p, .tip-excerpt p, .tip p {
    max-width: 94%;
    margin: 10px auto 20px;
  }
  .post-excerpt p.title, .post p.title, .tip-excerpt p.title, .tip p.title {
    margin: 15px auto;
    display: block;
  }
}

/* sidebar */
aside h3.widget-title, aside h3, aside h2.title {
  font-size: 26px;
  line-height: 23px;
  padding-top: 0;
  padding-bottom: 15px;
  margin: 20px 0 30px;
  font-weight: normal;
  color: #6b3e99;
  letter-spacing: 2px;
  border-bottom: 1px solid #ededed;
}

/* footer */
.before-footer {
  background-color: #18b8df;
  padding: 20px 0 20px;
  border-bottom: 1px dashed #dde9ad;
  text-align: center;
  margin: 0 auto;
}

.before-footer a {
  display: inline-block;
  padding: 10px;
  color: #FFF;
}

footer {
  background-color: #18b8df;
  padding: 20px 0 20px;
  color: #FFF;
  font-size: 14px;
  line-height: 21px;
  text-align: center;
}

footer .container {
  margin: auto;
  max-width: 100%;
}

footer a {
  display: none;
}

.back-to-top {
  position: fixed;
  bottom: 10px;
  right: 10px;
}

.back-to-top a {
  display: block;
  background-color: #6b3e99;
  color: #FFF;
  width: 40px;
  height: 40px;
  border-radius: 3px;
  font-size: 16px;
  line-height: 40px;
  text-align: center;
}

@media screen and (max-width: 830px) {
  footer .copyright {
    padding-bottom: 10px;
    display: block;
  }
}

/*section specific code */
/*styles for page-header partial*/
section.page-title-wrap {
  background-color: #18b8df !important;
  padding: 20px 0;
  text-align: center;
  background-attachment: fixed;
  background-position: center center;
  background-size: cover;
  margin-top: -1px;
}

section.page-title-wrap h1.page-title {
  font-size: 30px;
  line-height: 37px;
  color: #FFF;
  margin: 0 auto;
  text-shadow: 1px 1px 2px #888;
}

/*hero only needed when including the hero partial*/
section.hero {
  text-align: center;
  background-color: #FFF;
  height: 240px;
  background-position: center bottom;
  background-repeat: repeat-x;
  padding: 0;
  background-size: contain;
}

section.hero .inner {
  text-align: center;
  height: 240px;
  background-color: transparent !important;
  background-image: url(/images/header2.png);
  background-size: contain;
  background-position: center bottom;
  background-repeat: no-repeat;
}

@media screen and (max-width: 770px) {
  section.hero {
    height: 140px;
  }
  section.hero .inner {
    height: 140px;
  }
}

@media screen and (max-width: 440px) {
  section.hero {
    height: 100px;
  }
  section.hero .inner {
    height: 100px;
  }
}

/*home search only needed when including the search-jobs partial*/
section#home-search {
  text-align: center;
  background-color: #18b8df;
  padding: 20px 0 30px;
}

section#home-search h3 {
  color: #FFF;
  padding-top: 0;
  font-size: 2em;
  text-shadow: 1px 1px 2px #888;
  margin-bottom: 20px;
}

section#home-search form {
  max-width: 75%;
  text-align: center;
  margin: auto;
  padding: 0;
}

section#home-search input[type="text"] {
  width: 37%;
}

section#home-search input[type="submit"] {
  background-color: #e04590;
  border: 1px solid #e04590;
  color: #FFF;
  display: inline-block;
  height: 45px;
}

@media screen and (max-width: 782px) {
  section#home-search form {
    max-width: 90%;
  }
  section#home-search form input[type="text"] {
    width: 100%;
  }
}

/*home listings*/
.jobs {
  padding: 0;
  max-width: 1170px;
  margin: 10px auto 30px;
  text-align: center;
}

.jobs h3 {
  color: #6b3e99;
  text-shadow: 1px 1px 2px #888;
  margin: 20px auto 40px;
  text-align: center;
  font-size: 36px;
}

.jobs section {
  margin: 0 auto 35px;
  text-align: center;
  padding: 20px 10px 30px;
  background-color: #dde9ad;
  -webkit-border-radius: 10px;
  -moz-border-radius: 10px;
  border-radius: 10px;
  width: 94%;
}

.jobs h2.Title {
  padding-bottom: 10px;
}

.jobs h2.Title a {
  color: #6B3E99;
  font-family: "Pacifico", cursive;
  background-image: url(/images/job-icon.png);
  background-repeat: no-repeat;
  padding-left: 30px;
  background-position: 0;
  line-height: 35px;
}

.jobs a.Company {
  color: #e04590;
  font-size: 14px;
}

.jobs .TimePosted {
  display: block;
  color: #999;
  font-size: 12px;
}

.jobs .snippet {
  display: block;
  margin: 10px auto;
  text-align: center;
  max-width: 96%;
  font-size: 14px;
  line-height: 1.42857;
}

.jobs .snippet a.More {
  display: block;
  padding: 10px;
  margin-top: 10px;
  background-color: #18B8DF;
  color: #FFFFFF;
  font-weight: bold;
  font-size: 14px;
  width: 200px;
  border-radius: 8px;
  text-align: center;
  margin-left: auto;
  margin-right: auto;
}

/* top message */
.top-message {
  width: 100%;
  display: block;
  padding: 10px 0;
  text-align: center;
  margin: 0 auto;
  background-color: #6b3e99;
}

.top-message a {
  color: #FFF;
  max-width: 80%;
  margin: auto;
  display: block;
}

/* home features */
.home-features {
  display: block;
  width: 100%;
  background-color: #dde9ad;
  padding: 40px 0;
  margin: 0 auto;
  text-align: center;
}

.home-features h1 {
  color: #6b3e99;
  text-shadow: 1px 1px 2px #888;
  max-width: 80%;
  margin: 20px auto;
}

.home-features .feature-effect {
  text-align: center;
  position: relative;
}

.home-features .feature-effect .feature-box {
  text-align: center;
  position: relative;
}

.home-features .feature-effect .feature-box img.feature-box-image {
  text-align: center;
  background-color: #fff;
  border: 5px solid #18b8df;
  border-radius: 100%;
  margin: 20px auto;
  -webkit-transition: -webkit-transform 600ms ease-in-out;
  transition: transform 600ms ease-in-out;
}

.home-features .feature-effect .feature-box img.feature-box-image:hover {
  -webkit-transform: rotate(360deg);
  transform: rotate(360deg);
}

.home-features .feature-effect .feature-area {
  background-color: white;
  border-radius: 10px;
  padding: 60px 20px 10px;
  margin: -80px 10px 0;
}

.home-features .feature-effect .feature-area h2 {
  text-shadow: 1px 1px 2px #888;
}

/* featured-careers */
.featured-careers {
  display: block;
  width: 100%;
  background-color: #FFF;
  padding: 40px 0;
  margin: 0 auto;
  text-align: center;
}

.featured-careers h1 {
  color: #6b3e99;
  text-shadow: 1px 1px 2px #888;
}

.featured-careers .heading p {
  font-size: 20px;
  margin: 20px auto 40px;
}

.featured-careers img.featured-careers-img {
  border-radius: 50%;
  border: 5px solid #18b8df;
  text-align: center;
  margin: 10px auto 20px;
  -webkit-transition: -webkit-transform 600ms ease-in-out;
  transition: transform 600ms ease-in-out;
}

.featured-careers img.featured-careers-img:hover {
  -webkit-transform: rotate(360deg);
  transform: rotate(360deg);
}

.featured-careers .featured-careers-showcase-overlay {
  visibility: visible;
}

.featured-careers .featured-careers-showcase-overlay h4 {
  font-size: 22px;
}

.featured-careers .featured-careers-showcase-overlay h4 a {
  color: #e04590;
}
