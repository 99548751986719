$primary-color: #6b3e99;
$secondary-color: #18b8df;
$hero-bkg:#FFF;
$featured-image: url(/images/header2.png);
$icon-color:$primary-color;
$header-background:#FFF;

$page-header-bkg:$secondary-color;
$page-header:#FFF;

$toggle-icon-bar:white;
$line-color:#ededed;

$home-search-bkg:#18b8df;
$home-search-text:#FFF;
$home-search-submit-bkg:#e04590;
$home-search-submit-txt:#FFF;

@import url('https://fonts.googleapis.com/css?family=Pacifico');

/* header and navbar */
header{
  background-color: $header-background;
}

.navbar{
  margin-bottom:0;
  min-height:50px;
}
.navbar-brand {
  padding: 10px 0;
  img {
    max-width:160px;
  }
}

.navbar-nav>li>a {
  padding-top:20px;
  color: $primary-color;
}
.nav>li>a:focus, .nav>li>a:hover{
  background-color : transparent;
  color: #e04590;
}
.navbar-toggle {
  background-color:$secondary-color;
  margin-right:0;

  .icon-bar {
    background-color: $toggle-icon-bar;
  }
}

/* body, main and basic styles */
body{
  color:#666;
  font:16px "Lato", sans-serif;
  line-height: 23px;
  word-wrap: break-word;
}
a{
  color:$primary-color;
}
a:hover, a:active{
  color:#e04590;
}
h1, h2, h3, h4, h5, h6{
  color : $secondary-color;
  font-family: 'Pacifico', cursive;
  letter-spacing:1px;
}
ul{
  list-style: none;
  -webkit-padding-start:0;
}
p {
  margin:0 0 20px;
}
input[type="text"], input[type="email"], input[type="search"], input[type="password"], input[type="tel"], input[type="url"], input[type="date"], textarea{
  padding: 10px;
  border: 1px solid rgba(0, 0, 0, 0.05);
  width: 100%;
  margin: 0 0 20px;
  background-color: #f9f9f9;
  -webkit-border-radius: 3px;
  -moz-border-radius: 3px;
  border-radius: 3px;
}
input[type="reset"], input[type="button"], input[type="submit"]{
  background-color: $primary-color;
  border:1px solid $primary-color;
  color: #FFF;
  cursor: pointer;
  margin-bottom: 30px;
  text-transform: uppercase;
  display: block;
  text-align: center;
  height: 40px;
  padding: 0 20px;
  margin-top: 5px;
  -webkit-border-radius: 3px;
  -moz-border-radius: 3px;
  border-radius: 3px;
}
main {
  padding:30px 0 40px;
}
/* blog posts */

.tip-excerpt, .tip{
  padding: 0 0 20px;
  margin-bottom: 30px;
  text-align:left;
  clear:both;
  min-height: 180px;
  border-radius: 10px;
  background: #dceaae;
  p.title {
    font-family: "Pacifico", cursive;
    font-size:28px;
    margin:30px 0 20px;
    display:inline-block;
    width:auto;
    a:hover{
      text-decoration: none;
    }
  }
  p{
    width:98%;
    line-height:26px;
  }
  img{
    text-align:center;
    border: 5px solid #18b8df !important;
    border-radius: 50%;
    background-color: #FFF !important;
    float:left;
    max-width: 140px;
    margin: 15px;
    -webkit-transition: -webkit-transform 600ms ease-in-out;
    transition:         transform 600ms ease-in-out;
  }
  img:hover{
    -webkit-transform: rotate(360deg);
    transform: rotate(360deg);
  }
}
/*spotlight careers*/
.post-excerpt, .post{
  padding: 0 0 20px;
  margin-bottom: 40px;
  clear:both;
  min-height: 250px;
  border-radius: 10px;
  background: #dceaae;
  p.title{
    font-family: "Pacifico", cursive;
    font-size:28px;
    margin:40px 0 30px;
    display:inline-block;
    a:hover{
      text-decoration: none;
    }
  }
  p {
    max-width:98%;
    line-height:26px;
  }
  img{
    border: 5px solid #18b8df !important;
    border-radius: 50%;
    background-color: #FFF !important;
    float:left;
    max-width: 200px;
    margin:30px 15px 40px;
    -webkit-transition: -webkit-transform 600ms ease-in-out;
    transition:         transform 600ms ease-in-out;
  }
  img:hover{
    -webkit-transform: rotate(360deg);
    transform: rotate(360deg);
  }
  .excerpt{
    display:inline-block;
    width:88%;
  }
  a.button {
    color: #FFF;
    letter-spacing: .1em;
    background-color:#e04590;
    border-radius: 3px 3px 3px 3px;
    cursor: pointer;
    display: inline-block;
    font-size: 13px;
    line-height: 20px;
    padding: 9px 18px;
    text-align: center;
  }
  a.button:hover{
    text-decoration: none;
    background-color:$secondary-color;
  }
}
.pagination {
  display: block;
  min-height: 50px;
  text-align: center;
  margin-bottom:50px;
  a {
    color: $primary-color;
    border: 1px solid $line-color;
    padding: 5px 10px;
    text-align: center;
    margin: 5px;
    border-radius: 4px;
    display:inline-block;
  }

  a:hover {
    border: 1px solid $primary-color;
    text-decoration: none;
  }

}
@media screen and (max-width:990px){
  .post-excerpt img, .post img, .tip-excerpt img, .tip img{
    float:none;
    margin:15px auto;
  }
  .post-excerpt, .post, .tip-excerpt, .tip{
    text-align:center;
    p{
      max-width:94%;
      margin: 10px auto 20px;
    }
  }
  .post-excerpt p.title, .post p.title, .tip-excerpt p.title, .tip p.title{
    margin:15px auto;
    display:block;
  }

}
/* sidebar */
aside h3.widget-title, aside h3, aside h2.title {
  font-size: 26px;
  line-height: 23px;
  padding-top:0;
  padding-bottom: 15px;
  margin:20px 0 30px;
  font-weight: normal;
  color:$primary-color;
  letter-spacing: 2px;
  border-bottom: 1px solid $line-color;
}
/* footer */
.before-footer{
  background-color:$secondary-color;
  padding: 20px 0 20px;
  border-bottom: 1px dashed #dde9ad;
  text-align:center;
  margin:0 auto;
  a{
    display: inline-block;
    padding:10px;
    color:#FFF;
  }
}
footer{
  background-color:$secondary-color;
  padding: 20px 0 20px;
  color:#FFF;
  font-size: 14px;
  line-height: 21px;
  text-align:center;
  .container{
    margin: auto;
    max-width: 100%;
  }
  a {
    display:none;
  }
}

.back-to-top {
  position: fixed;
  bottom: 10px;
  right: 10px;

  a {
    display:block;
    background-color: $primary-color;
    color: #FFF;
    width: 40px;
    height: 40px;
    border-radius: 3px;
    font-size: 16px;
    line-height: 40px;
    text-align: center;
  }
}
@media screen and (max-width:830px) {
  footer .copyright{
    padding-bottom: 10px;
    display: block;
  }
}
/*section specific code */
/*styles for page-header partial*/
section.page-title-wrap {
  background-color: $page-header-bkg !important;
  padding               :20px 0;
  text-align            : center;
  background-attachment : fixed;
  background-position   : center center;
  background-size       : cover;
  margin-top            : -1px;

  h1.page-title {
    font-size: 30px;
    line-height: 37px;
    color: $page-header;
    margin:0 auto;
    text-shadow:1px 1px 2px #888;
  }
}
/*hero only needed when including the hero partial*/
section.hero{
  text-align:center;
  background-color: $hero-bkg;
  height:240px;
  background-position: center bottom;
  background-repeat:repeat-x;
  padding:0;
  background-size:contain;
  .inner{
    text-align:center;
    height:240px;
    background-color: transparent !important;
    background-image:$featured-image;
    background-size: contain;
    background-position: center bottom;
    background-repeat:no-repeat;
  }
}
@media screen and (max-width:770px){
  section.hero {
      height:140px;
    .inner {
        height:140px;
    }
  }
}
@media screen and (max-width:440px){
  section.hero {
    height:100px;
    .inner {
      height:100px;
    }
  }
}
/*home search only needed when including the search-jobs partial*/
section#home-search{
  text-align:center;
  background-color:$home-search-bkg;
  padding: 20px 0 30px;
  h3{
    color:$home-search-text;
    padding-top: 0;
    font-size: 2em;
    text-shadow:1px 1px 2px #888;
    margin-bottom:20px;
  }
  form{
    max-width: 75%;
    text-align: center;
    margin: auto;
    padding:0;
  }
  input[type="text"]{
    width:37%;
  }
  input[type="submit"]{
    background-color:$home-search-submit-bkg;
    border:1px solid $home-search-submit-bkg;
    color:$home-search-submit-txt;
    display:inline-block;
    height:45px;
  }
}
@media screen and (max-width:782px) {
  section#home-search form {
    max-width : 90%;
    input[type="text"]{
      width:100%;
    }
  }
}


/*home listings*/
.jobs {
  padding: 0;
  max-width:1170px;
  margin:10px auto 30px;
  text-align:center;
  h3{
    color: $primary-color;
    text-shadow: 1px 1px 2px #888;
    margin:20px auto 40px;
    text-align:center;
    font-size:36px;
  }
  section{
    margin:0 auto 35px;
    text-align: center;
    padding: 20px 10px 30px;
    background-color: #dde9ad;
    -webkit-border-radius: 10px;
    -moz-border-radius: 10px;
    border-radius: 10px;
    width:94%;
  }
  h2.Title {
    padding-bottom: 10px;
    a {
      color: #6B3E99;
      font-family: "Pacifico", cursive;
      background-image: url(/images/job-icon.png);
      background-repeat: no-repeat;
      padding-left: 30px;
      background-position: 0;
      line-height: 35px;
    }
  }
  a.Company{
    color: #e04590;
    font-size:14px;
  }
  .TimePosted {
    display:block;
    color:#999;
    font-size:12px;
  }
  .snippet {
    display:block;
    margin:10px auto;
    text-align:center;
    max-width:96%;
    font-size: 14px;
    line-height: 1.42857;
    a.More{
      display: block;
      padding: 10px;
      margin-top: 10px;
      background-color: #18B8DF;
      color: #FFFFFF;
      font-weight: bold;
      font-size: 14px;
      width: 200px;
      border-radius: 8px;
      text-align: center;
      margin-left: auto;
      margin-right: auto;
    }
  }
}



/* top message */
.top-message{
  width:100%;
  display:block;
  padding:10px 0;
  text-align: center;
  margin:0 auto;
  background-color:$primary-color;
  a{
    color:#FFF;
    max-width: 80%;
    margin: auto;
    display: block;
  }
}

/* home features */
.home-features{
  display:block;
  width:100%;
  background-color:#dde9ad;
  padding: 40px 0;
  margin:0 auto;
  text-align:center;
  h1{
    color: #6b3e99;
    text-shadow: 1px 1px 2px #888;
    max-width:80%;
    margin:20px auto;
  }
  .feature-effect{
    text-align:center;
    position:relative;
    .feature-box{
      text-align:center;
      position: relative;
      img.feature-box-image {
        text-align: center;
        background-color: #fff;
        border: 5px solid #18b8df;
        border-radius: 100%;
        margin: 20px auto;
        -webkit-transition: -webkit-transform 600ms ease-in-out;
        transition: transform 600ms ease-in-out;
      }
      img.feature-box-image:hover {
        -webkit-transform: rotate(360deg);
        transform: rotate(360deg);
      }
    }
    .feature-area {
      background-color: white;
      border-radius:10px;
      padding: 60px 20px 10px;
      margin: -80px 10px 0;
      h2 {
        text-shadow: 1px 1px 2px #888;
      }
    }
  }

}
/* featured-careers */
.featured-careers{
  display:block;
  width:100%;
  background-color:#FFF;
  padding: 40px 0;
  margin:0 auto;
  text-align:center;
  h1{
    color: #6b3e99;
    text-shadow:1px 1px 2px #888
  }
  .heading p{
    font-size:20px;
    margin:20px auto 40px;
  }
  img.featured-careers-img{
    border-radius: 50%;
    border: 5px solid #18b8df;
    text-align:center;
    margin:10px auto 20px;
    -webkit-transition: -webkit-transform 600ms ease-in-out;
    transition: transform 600ms ease-in-out;
  }
  img.featured-careers-img:hover{
    -webkit-transform: rotate(360deg);
    transform: rotate(360deg);
  }
  .featured-careers-showcase-overlay{
    visibility: visible;
    h4{
      font-size:22px;
      a{
        color:#e04590;
      }
    }
  }
}